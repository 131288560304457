  /* Existing Styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    height: 100%;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #89997A;
    color: #333;
  }

  .container {
    text-align: center;
    background: #fff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .content h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .content p {
    font-size: 1.2em;
  }

  /* Instagram Button Styling */
  .instagram-button {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4);
    border-radius: 30px;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .instagram-button i {
    margin-right: 8px;
  }

  .instagram-button:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 15px rgba(81, 91, 212, 0.4);
  }

  /* Icon Styling */
  .content a i.fab.fa-instagram {
    font-size: 36px;
    background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    transition: transform 0.3s ease;
  }

  .content a i.fab.fa-instagram:hover {
    transform: scale(1.1);
  }

  .shop-title {
    white-space: nowrap; /* Prevent text from wrapping */
    font-size: 2.5em; /* Adjust size as necessary */
    overflow: hidden; /* Hide overflow if the text exceeds the container */
    text-overflow: ellipsis; /* Optional: add ellipsis if text is too long */
    max-width: 100%; /* Ensure it fits within the container */
}